import { defineStore } from 'pinia';
import type { IAPIJobAlarmSubscription, IAPIJobFilterBasicRequest } from '@hokify/common';
import { useUserProfileStore } from './user-profile';

export interface IJobAlarmSubscriptionsState {
	allSubscriptions: IAPIJobAlarmSubscription[];
	interval: number;
}

let loadedJobAlarm;

export const useUserJobAlarmStore = defineStore('userJobAlarm', {
	state: (): IJobAlarmSubscriptionsState => ({
		allSubscriptions: [],
		interval: 12
	}),
	actions: {
		async setJobAlarm(jobAlarmRequest: IAPIJobFilterBasicRequest) {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/jobalarm', {
					method: 'PUT',
					body: {
						filter: jobAlarmRequest
					}
				});
				if (res.subscriptions?.length) {
					// make sure to set updateHasActivatedJobAlarm to true
					const userProfileStore = useUserProfileStore();
					userProfileStore.setUpdatedElements({ 'jobFilter.hasActivatedJobAlarm': true });
					loadedJobAlarm = res.subscriptions;
					// update subscriptions with result from API
					this.updateJobAlarmSubscriptions(loadedJobAlarm);
				}
				return res;
			} catch (error: any) {
				console.error('setJobAlarm failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		async getJobAlarmSubscriptions(forceReload = false) {
			// if API call was already made and result is available, return it
			if (loadedJobAlarm && !forceReload) {
				return loadedJobAlarm;
			}

			// if not, call API and save result into store
			try {
				const jobAlarm = await this.$nuxt.$hokFetch('/app-api/jobseeker/jobalarm');
				this.updateJobAlarmSubscriptions(jobAlarm.subscriptions);
				this.setUpdatedJobAlarmInterval(jobAlarm.interval);
				loadedJobAlarm = jobAlarm.subscriptions;
				return loadedJobAlarm;
			} catch (error: any) {
				if (error?.response?.data?.code === 'NOT_FOUND') {
					loadedJobAlarm = []; // user has no jobAlarm
					return loadedJobAlarm;
				}
				console.error('getJobAlarmSubscriptions failed', error);
			}
		},
		async deleteJobAlarm(subscriptionId) {
			try {
				const subscriptions = await this.$nuxt.$hokFetch(
					`/app-api/jobseeker/jobalarm/${subscriptionId}`,
					{ method: 'DELETE' }
				);

				if (subscriptions) {
					this.updateJobAlarmSubscriptions(subscriptions);
				}
			} catch (error: any) {
				console.error('deleteJobAlarm failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		viewJobAlarm(subscriptionId) {
			try {
				return this.$nuxt.$hokFetch(`/app-api/jobseeker/jobalarm/view/${subscriptionId}`, {
					method: 'POST'
				});
			} catch (error: any) {
				console.error('viewJobAlarm failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		async editJobAlarm({ jobalarmRequest, subscriptionId }) {
			try {
				const res = await this.$nuxt.$hokFetch(`/app-api/jobseeker/jobalarm/${subscriptionId}`, {
					method: 'POST',
					body: {
						filter: jobalarmRequest
					}
				});
				if (res.subscriptions?.length) {
					loadedJobAlarm = res.subscriptions;
					// update subscriptions with result from API
					this.updateJobAlarmSubscriptions(loadedJobAlarm);
				}
				return loadedJobAlarm;
			} catch (error: any) {
				console.error('editJobAlarm failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		async updateJobAlarmInterval(interval) {
			try {
				const res = await this.$nuxt.$hokFetch(`/app-api/jobseeker/jobalarm/update-interval`, {
					method: 'POST',
					body: {
						interval
					}
				});
				if (res.success) {
					this.setUpdatedJobAlarmInterval(interval);
					return true;
				}
				return false;
			} catch (error: any) {
				console.error('updateJobAlarmInterval failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		updateJobAlarmSubscriptions(jobAlarmSubscriptions) {
			this.allSubscriptions = jobAlarmSubscriptions;
		},
		// used to be "updateJobAlarmInterval" mutation
		setUpdatedJobAlarmInterval(interval: number) {
			this.interval = interval;
		}
	}
});
